<template>
  <div class="full_popup top-env-padding">
    <MobileSteps v-if="isMobile" :activeComponent="activeComponent" :wizardSteps="wizardSteps" />
    <DesktopWizardTopBar v-else :activeComponent="activeComponent" :wizardSteps="wizardSteps" />
    <div class="row mx-0" v-if="isMobile">
      <StepCount :activeComponent="activeComponent" :wizardSteps="wizardSteps"  />
      <ThePage>
        <slot />
      </ThePage>
    </div>
    <TheCurve v-else>
      <div class="row mx-0 justify-content-center">
        <StepCount :activeComponent="activeComponent" :wizardSteps="wizardSteps"  />
        <ThePage>
          <slot/>
        </ThePage>
      </div>
    </TheCurve>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  props: ['activeComponent', 'wizardSteps'],
  components: {
    DesktopWizardTopBar: defineAsyncComponent(() => import('./DesktopWizardTopBar.vue')),
    StepCount: defineAsyncComponent(() => import('./StepCount.vue')),
    ThePage: defineAsyncComponent(() => import('./ThePage.vue')),
    TheCurve: defineAsyncComponent(() => import('./TheCurve.vue')),
    MobileSteps: defineAsyncComponent(() => import('./MobileSteps.vue'))
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  }
}
</script>
<style scoped>
  .full_popup {
    position: fixed;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgb(249, 250, 251);
    padding: 0;
  }
</style>
